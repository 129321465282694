function Countdowns({ days, hours, minutes, seconds }) {
  return (
    <div className="flex items-start justify-center w-5/6 gap-3 count-down-main mt-5">
      <div className="timer w-16">
        <div className=" bg-indigo-600 py-4 px-2 rounded-lg overflow-hidden">
          <h3 className="countdown-element days font-Cormorant font-semibold text-2xl text-white text-center">{days}</h3>
        </div>
        <p className="text-lg font-Cormorant font-medium text-white mt-1 text-center w-full">days</p>
      </div>
      <h3 className="font-manrope font-semibold text-2xl text-gray-900">:</h3>
      <div className="timer w-16">
        <div className=" bg-indigo-600 py-4 px-2 rounded-lg overflow-hidden">
          <h3 className="countdown-element hours font-Cormorant font-semibold text-2xl text-white text-center">{hours}</h3>
        </div>
        <p className="text-lg font-Cormorant font-normal text-white mt-1 text-center w-full">hours</p>
      </div>
      <h3 className="font-manrope font-semibold text-2xl text-gray-900">:</h3>
      <div className="timer w-16">
        <div className=" bg-indigo-600 py-4 px-2 rounded-lg overflow-hidden">
          <h3 className="countdown-element minutes font-Cormorant font-semibold text-2xl text-white text-center">{minutes}</h3>
        </div>
        <p className="text-lg font-Cormorant font-normal text-white mt-1 text-center w-full">minutes</p>
      </div>
      <h3 className="font-manrope font-semibold text-2xl text-gray-900">:</h3>
      <div className="timer w-16">
        <div
          className={
            seconds > 5 ? " bg-indigo-600 py-4 px-2 rounded-lg overflow-hidden " : " bg-red-600 py-4 px-2 rounded-lg overflow-hidden "
          }
        >
          <h3
            className={
              seconds > 5
                ? "countdown-element seconds font-Cormorant font-semibold text-2xl text-white text-center animate-countinsecond"
                : "countdown-element seconds font-Cormorant font-semibold text-2xl text-white text-center animate-countinsecond animate-pulse"
            }
          >
            {seconds}
          </h3>
        </div>
        <p className="text-lg font-Cormorant font-normal text-white mt-1 text-center w-full">seconds</p>
      </div>
    </div>
  );
}

export default Countdowns;
