import { useRef, useState, React } from "react";
import Countdowns from "./components/countdown";
import Countdown from "react-countdown";
import "./App.css";
import Mainpage from "./components/Mainpage";
import { Fireworks } from "@fireworks-js/react";

function App() {
  const [currentMusicDetails, setCurrentMusicDetails] = useState({
    songName: "Willow",
    songArtist: "Taylor Swift",
    songSrc: "./Assets/songs/Taylor Swift - willow (Official Lyric Video).mp3",
    songAvatar: "./Assets/Images/image7.jpg",
  });
  //UseStates Variables
  const [audioProgress, setAudioProgress] = useState(0);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [musicIndex, setMusicIndex] = useState(0);
  const [musicTotalLength, setMusicTotalLength] = useState("04 : 38");
  const [musicCurrentTime, setMusicCurrentTime] = useState("00 : 00");
  const [videoIndex, setVideoIndex] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [dates, setDates] = useState(
    new Date() > new Date(Date.UTC(2024, 7, 28, 17, 0, 1)) ? Date.now() + 2000 : new Date(Date.UTC(2024, 7, 28, 17, 0, 1))
  );
  const [fireworks, setFireworks] = useState(false);

  const toggleFireworks = () => {
    setFireworks(!fireworks);
  };

  const currentAudio = useRef();
  const ref = useRef(null);
  const handleMusicProgressBar = (e) => {
    setAudioProgress(e.target.value);
    currentAudio.current.currentTime = (e.target.value * currentAudio.current.duration) / 100;
  };

  //Change Avatar Class
  let avatarClass = ["objectFitCover", "objectFitContain", "none"];
  const [avatarClassIndex, setAvatarClassIndex] = useState(0);
  const handleAvatar = () => {
    if (avatarClassIndex >= avatarClass.length - 1) {
      setAvatarClassIndex(0);
    } else {
      setAvatarClassIndex(avatarClassIndex + 1);
    }
  };

  //Play Audio Function
  const handleAudioPlay = () => {
    if (currentAudio.current.paused) {
      currentAudio.current.play();
      setIsAudioPlaying(true);
    } else {
      currentAudio.current.pause();
      setIsAudioPlaying(false);
    }
  };

  const musicAPI = [
    {
      songName: "Willow",
      songArtist: "Taylor Swift",
      songSrc: "./Assets/songs/Taylor Swift - willow (Official Lyric Video).mp3",
      songAvatar: "./Assets/Images/image7.jpg",
    },
    {
      songName: "All Too Well",
      songArtist: "Taylor Swift",
      songSrc: "./Assets/songs/All Too Well (10 Minute Version) (Taylor's Version) (From The Vault) (Lyric Video).mp3",
      songAvatar: "./Assets/Images/image4.jpg",
    },
    {
      songName: "Dandelions",
      songArtist: "Ruth B.",
      songSrc: "./Assets/songs/Ruth B. - Dandelions (Lyrics).mp3",
      songAvatar: "./Assets/Images/image2.jpg",
    },
    {
      songName: "Enchanted",
      songArtist: "Taylor Swift",
      songSrc: "./Assets/songs/Taylor Swift - Enchanted (Taylor's Version) (Lyric Video).mp3",
      songAvatar: "./Assets/Images/image3.jpg",
    },
    {
      songName: "Haunted",
      songArtist: "Taylor Swift",
      songSrc: "./Assets/songs/Taylor Swift - Haunted (Taylor's Version) (Lyric Video).mp3",
      songAvatar: "./Assets/Images/image5.jpg",
    },
    {
      songName: "The 1",
      songArtist: "Taylor Swift",
      songSrc: "./Assets/songs/Taylor Swift - the 1 (Official Lyric Video).mp3",
      songAvatar: "./Assets/Images/image6.jpg",
    },
    {
      songName: "Make You Feel My Love",
      songArtist: "Adele",
      songSrc: "./Assets/songs/Adele - Make You Feel My Love (Lyrics).mp3",
      songAvatar: "./Assets/Images/image1.jpg",
    },
    {
      songName: "Line Without a Hook",
      songArtist: "Ricky Montgomery",
      songSrc: "./Assets/songs/Ricky Montgomery - Line Without a Hook (Official Lyric Video).mp3",
      songAvatar: "./Assets/Images/image29.jpg",
    },
  ];

  const handleNextSong = () => {
    if (musicIndex >= musicAPI.length - 1) {
      let setNumber = 0;
      setMusicIndex(setNumber);
      updateCurrentMusicDetails(setNumber);
    } else {
      let setNumber = musicIndex + 1;
      setMusicIndex(setNumber);
      updateCurrentMusicDetails(setNumber);
    }
  };

  const handlePrevSong = () => {
    if (musicIndex === 0) {
      let setNumber = musicAPI.length - 1;
      setMusicIndex(setNumber);
      updateCurrentMusicDetails(setNumber);
    } else {
      let setNumber = musicIndex - 1;
      setMusicIndex(setNumber);
      updateCurrentMusicDetails(setNumber);
    }
  };

  const updateCurrentMusicDetails = (number) => {
    let musicObject = musicAPI[number];
    currentAudio.current.src = musicObject.songSrc;
    currentAudio.current.play();
    setCurrentMusicDetails({
      songName: musicObject.songName,
      songArtist: musicObject.songArtist,
      songSrc: musicObject.songSrc,
      songAvatar: musicObject.songAvatar,
    });
    setIsAudioPlaying(true);
  };

  const handleAudioUpdate = () => {
    //Input total length of the audio
    let minutes = Math.floor(currentAudio.current.duration / 60);
    let seconds = Math.floor(currentAudio.current.duration % 60);
    let musicTotalLength0 = `${minutes < 10 ? `0${minutes}` : minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`;
    setMusicTotalLength(musicTotalLength0);

    //Input Music Current Time
    let currentMin = Math.floor(currentAudio.current.currentTime / 60);
    let currentSec = Math.floor(currentAudio.current.currentTime % 60);
    let musicCurrentT = `${currentMin < 10 ? `0${currentMin}` : currentMin} : ${currentSec < 10 ? `0${currentSec}` : currentSec}`;
    setMusicCurrentTime(musicCurrentT);

    const progress = parseInt((currentAudio.current.currentTime / currentAudio.current.duration) * 100);
    setAudioProgress(isNaN(progress) ? 0 : progress);
  };

  const vidArray = ["./Assets/Videos/video2.mp4", "./Assets/Videos/video3.mp4", "./Assets/Videos/video1.mp4", "./Assets/Videos/video4.mp4"];

  const handleChangeBackground = () => {
    if (videoIndex >= vidArray.length - 1) {
      setVideoIndex(0);
    } else {
      setVideoIndex(videoIndex + 1);
    }
  };
  //countdown
  // Random component
  const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      toggleFireworks();
      setCompleted(true);
    } else {
      return <Countdowns days={days} hours={hours} minutes={minutes} seconds={seconds} />;
    }
  };
  if (fireworks) {
    setTimeout(() => {
      toggleFireworks();
    }, 6000);
  }
  // logic countdown
  return (
    <>
      {fireworks && (
        <>
          <Fireworks
            ref={ref}
            className="z-40"
            options={{ opacity: 0.5 }}
            style={{
              sound: {
                enabled: true,
                files: ["./Assets/songs/fireworks.mp3.mp3", "./Assets/songs/fireworks.mp3.mp3"],
                volume: {
                  min: 4,
                  max: 8,
                },
              },
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "fixed",
              background: "#000",
              explosion: 10,
              lineWidth: {
                explosion: {
                  min: 2,
                  max: 4,
                },
                trace: {
                  min: 2,
                  max: 4,
                },
              },
            }}
          />
        </>
      )}
      <div className="container flex items-center justify-center">
        <audio
          src="./Assets/songs/Taylor Swift - willow (Official Lyric Video).mp3"
          ref={currentAudio}
          onEnded={handleNextSong}
          onTimeUpdate={handleAudioUpdate}
        ></audio>
        <video src={vidArray[videoIndex]} muted autoPlay onEnded={handleChangeBackground} className="backgroundVideo"></video>
        <div className="blackScreen"></div>
        {completed && <Mainpage />}
        <div
          className={
            completed
              ? "flex-col z-30 bottom-0 p-6 w-64 md:w-80 xl:w-5/12 mb-3 fixed text-center rounded-[36px] shadow-[0_0_20px_rgba(26,26,26,0.1),0_0_40px_rgba(26,26,26,0.1),0_0_80px_rgba(26,26,26,0.1)] backdrop-blur-[15px] font-semibold"
              : "music-Container"
          }
        >
          {!completed && (
            <>
              <p className="font-extrabold text-3xl text-white ">{currentMusicDetails.songName}</p>
              <p className="music-Artist-Name">{currentMusicDetails.songArtist}</p>
            </>
          )}
          {!completed && (
            <img
              src={currentMusicDetails.songAvatar}
              className={avatarClass[avatarClassIndex]}
              onClick={handleAvatar}
              alt="song Avatar"
              id="songAvatar"
            />
          )}
          <p className="font-extrabold text-3xl text-white ">{currentMusicDetails.songName}</p>
          <p className="music-Artist-Name">{currentMusicDetails.songArtist}</p>
          <div className="musicTimerDiv">
            <p className="musicCurrentTime">{musicCurrentTime}</p>
            <p className="musicTotalLenght">{musicTotalLength}</p>
          </div>
          <input
            type="range"
            name="musicProgressBar"
            className="musicProgressBar"
            value={audioProgress}
            onChange={handleMusicProgressBar}
          />
          <div className="musicControlers">
            <i className="fa-solid fa-backward musicControler" onClick={handlePrevSong}></i>
            <i className={`fa-solid ${isAudioPlaying ? "fa-pause-circle" : "fa-circle-play"} playBtn`} onClick={handleAudioPlay}></i>
            <i className="fa-solid fa-forward musicControler" onClick={handleNextSong}></i>
          </div>
          {!completed && (
            <div className="flex justify-center flex-col items-center mt-5">
              <h1>Menuju First Anniversary ♡ :</h1>
              <Countdown date={dates} renderer={renderer} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default App;
