import Carousel from "./carousel.component";

function Mainpage() {
  let slides = [
    "./Assets/carousel/1 (1).jpg",
    "./Assets/carousel/1 (2).jpg",
    "./Assets/carousel/1 (3).jpg",
    "./Assets/carousel/1 (4).jpg",
    "./Assets/carousel/1 (5).jpg",
    "./Assets/carousel/1 (6).jpg",
    "./Assets/carousel/1 (7).jpg",
    "./Assets/carousel/1 (8).jpg",
    "./Assets/carousel/1 (9).jpg",
    "./Assets/carousel/1 (10).jpg",
    "./Assets/carousel/1 (11).jpg",
    "./Assets/carousel/1 (12).jpg",
    "./Assets/carousel/1 (13).jpg",
    "./Assets/carousel/1 (14).jpg",
    "./Assets/carousel/1 (15).jpg",
    "./Assets/carousel/1 (16).jpg",
    "./Assets/carousel/1 (17).jpg",
    "./Assets/carousel/1 (18).jpg",
    "./Assets/carousel/1 (19).jpg",
    "./Assets/carousel/1 (20).jpg",
    "./Assets/carousel/1 (21).jpg",
    "./Assets/carousel/1 (22).jpg",
    "./Assets/carousel/1 (23).jpg",
    "./Assets/carousel/1 (24).jpg",
    "./Assets/carousel/1 (25).jpg",
    "./Assets/carousel/1 (26).jpg",
    "./Assets/carousel/1 (27).jpg",
    "./Assets/carousel/1 (28).jpg",
    "./Assets/carousel/1 (29).jpg",
    "./Assets/carousel/1 (30).jpg",
  ];
  return (
    <div className="flex flex-col w-auto ml-8 mr-8 sm:ml-20 sm:mr-20 z-20 mb-80 md:ml-40 md:mr-40 lg:ml-64 lg:mr-64 xl:ml-96 xl:mr-96 p-1 md:p-3 lg:p-7 mt-28 rounded-lg backdrop-opacity-60 backdrop-invert">
      <div className=" mx-7">
        <h1 className="text-6xl font-extrabold text-white mb-9 text-center mt-10 bg-white-600/75 ">
          Happy One Year
          <br />
          Anniversary My Willow!♡
        </h1>
        <p className="mb-4 text-pretty font-black">
          Selamat telah melalui banyak hal, selamat telah mengusahakan banyak hal, selamat telah melewati ups and downs, selamat telah
          membuat diri masing-masing jadi lebih baik, selamat telah melakukan banyak proses, selamat untuk segala yang udah kita rencanakan,
          dan selamat berusaha dan berjuang lagi kedepannya untuk mencapai hal yang selalu kita bicarakan dan impikan.
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p className="mb-4 text-pretty font-black">
          Terima kasih banyak atas kehadiran kamu yang tidak berhenti ngebuat aku terus bersyukur dan mendapatkan banyak hal mulai dari
          mengejar mimpi hingga mendapatkan apa aja yang aku butuhkan dan aku mau. Yepp!! bener itu juga berkat kamu.
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Kalau di lihat lagi, perjalanan kita memang tidak mudah. Kalau di inget saja rasanya ngga tau kenapa bisa ngelaluin itu. Tapi pas
          aku tanya lagi diri aku, mungkin jawabannya ya karena ngejalaninnya bareng kamu kali ya....
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Kebayang ga sih kamu gimana ya hidup aku dan kamu kalau dulu aku ga ngechat kamu lagi waktu kita udh lost contact. Gimana ya hidup
          aku dan kamu kalau kamu ga ngebales semua pesan aku dengan excited dulu. Gimana ya hidup aku dan kamu kalau kamu ga
          ngeshopee-foodin aku psp. Aku bener-bener ga kebayang wkwkwk.
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Mulai dari awal lagi kontakan, aku yang dengan tipe seperti itu pada saat itu kaya bisa ya langsung kebuka lagi hatinya untuk
          memulai semuanya lagi bareng kamu. Segitu hebat dan menawannya kamu, sampai hari-hari dimana kita intense lagi chatan itu kaya
          sangat menyenangkan dan memorable banget wkwkwk.
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Mulai dari awal pertama banget kita telponan bareng itu rasanya unreal banget ya. Kamu yang setiap hari aku cuman bisa perhatiin
          dari jauh, kamu yang aku sangka cuman bisa inginkan dan dambakan tapi susah buat di dapetin, bisa telponan cerita banyak dan
          GILAAAAA AKU SENENG BANGET DISITU HAHAHAHHA.
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Terus yaaaaa... Pas pertama kali ngedate, aku tuh deg degan banget... Langsung deh aku pakai outfit yang aku rasa yang paling
          bagus yang aku punya wkwkwkwk. Pas awal banget kaya masih ngerasa ini nyata ga sih, INI BENERAN NIH AKU JALAN SAMA SELSA YANG DARI
          DLU AKU LIATIN DAN KAGUMI DOANG HUAAAAAA !!!! UNREAAAL BANGETTTT RASANYAA!!!!
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          KAMU INGET GAAA???? Pas awal banget kita photobooth yang berdua doang. ituu tuhhh aku kaya ga pede, bener bener takut kamu ngerasa
          fotonya ga bagus karena aku nya jelek ☹. Tapi ya begitu aku ngelihat respon kamu kaya aku bener bener ngerasa bisa ya ada orang
          yang menghargai dan memandang aku segitunya. Gila aku bener bener seneng banget hehe
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Akhirnya kita terus menikmati hari-hari kita bareng, terus banyak jalan-jalan. OH IYAAA !!! Apalagi kita pas ke dufan kamu inget
          ga? gila aku yang sepenakut itu bisa jadi si pemberani wkwkwkkwkwk. Yaaahh begitulah kehadiran kamu di aku, berasa aku bisa
          ngelewatin apapun.
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Terus ga lama kita double date tuh sama dira bintang, buset seruuuu banget bisa ketemu temen dan yang pasti bisa kita mintain
          tolong foto kalau kita lagi jalan hehehehe. Mana kebetulan sefrekruensi yaah? suka banget cobain hal hal baru, bukan yang monoton
          wkwkk
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Selama setahun kita bener bener BAAAAAAAAANYYYAAAAK BANGET ngelakuin hal hal baru, hal hal seru, hal hal yang bisa bikin kita
          saling nyenengin satu sama lain. Makasih ya udh selalu ngajak aku dan ngasih ide ide yang seru. LOVIN IT!!
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Tapi ....
          <br />
          Aku minta maaf ya kalau aku pernah bikin kamu kecewa, aku minta maaf ya kalau aku pernah bikin kamu sedih, aku minta maaf ya kalau
          aku sering bikin kamu ada di kondisi yang kamu ga suka dan buat kamu sedih. Aku minta maaf aku sering banget bawa keadaan dan
          kondisi yang buat kamu sedih dan tersakiti. Maafin aku ya... Aku harap kamu tau gimana berharganya bahagia dan senyumnya kamu. Aku
          harap kamu juga bisa ngerasain apapun yang selama ini aku usahakan dan pastinya akan selalu aku lebih usahakan.
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Aku yakin, seperti yang aku selama ini bilang. Gimana aku sangat menyayangi kamu dan kita. Gimana sangat luar biasanya hubungan
          yang kita bangun ini. Gimana hebatnya hubungan ini bisa saling menguatkan mengasihi menyayangi dan membantu satu sama lain. Aku
          mau menjaga ini semua, aku mau menjaga kamu, aku mau menjaga kita, dan aku mau menjaga hubungan kita. Aku harap kita punya 1
          tujuan yang sama ya ♡
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Jalan kedepannya pasti akan lebih seru. Mungkin banyak yang kamu khawatirin, banyak yang buat kamu takut, banyak yang bikin kamu
          kepikiran, banyak yang buat kamu berpikir ulang mengenai apapun yang akan kita hadapi. Tapi inget, ada aku yang akan selalu ada di
          depan, belakang, dan samping kamu di setiap fase kehidupan kamu. Aku akan selalu ada untuk kamu, aku akan selalu ada untuk kita.
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          AYOO kita bangun , usahakan, perjuangkan semua impian dan keinginan kita yang selama ini kita sama sama bicarakan. Aku yakin kita
          bisa sampe di titik itu. Kenapa aku yakin? karena aku ngejalaninnya bareng kamu ♡ Semoga kamu juga bisa ngerasain hal yang sama ya
          ...
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p>
          Sesuai hasil peermainan penguin, kita InsyaAllah akan bisa ngejalanin semua dengan lebih baik di awal tahun 2025 ya. Aku ada kabar
          bahagia, SOOO STAY TUNED YAAAA !!! SEE YOU TONIGHT ♡
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
        <p className="text-center">
          I Love you more, Love you to the moon and to saturn, Love you always in All Ways...
          <br />
          My one and only, My Willow, My Selsa, My soon to be fiance, My soon to be Love of My Life♡
        </p>
        <br />
        <p className="text-center">I LOVE YOU ♡</p>
        <br />
      </div>
      <div className="flex items-center justify-center">
        <Carousel slides={slides} />
      </div>
    </div>
  );
}

export default Mainpage;
